<template>
  <v-card  class="elevation-2" :style="{background: $wiDark ? '' : '#F2F2F2'}">
    <v-list subheader one-line dense :style="{background: $wiDark ? '' : '#F2F2F2'}">
      <wi-time-line-item-person :item="item" :action="action"></wi-time-line-item-person>
      <v-divider></v-divider>
    </v-list>
    <v-card-text>
      <div v-html="item.description"></div>
    </v-card-text>
  </v-card>
</template>
<script>
  import WiTimeLineItemPerson from '@/default/component/WiTimeLine/Items/WiTimeLineItemPerson'
  export default {
    name: 'WiTimeLineItemCreate',
    props: {
      item: {
        required: true,
        default: {}
      },
      action: {
        default: 'Executou:'
      }
    },
    components: {
      WiTimeLineItemPerson
    }
  }
</script>
