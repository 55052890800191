<template>
  <v-list-tile avatar>
    <v-list-tile-avatar style="padding-right: 10px;">
      <v-avatar>
        <v-img src="static/icons/user_icon_1.png" style="max-width: 40px; max-height: 40px;" alt="avatar"/>
      </v-avatar>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-sub-title v-if="before">{{action}} </v-list-tile-sub-title>
      <v-list-tile-title style="font-size: 16px;">{{item.person.name}}</v-list-tile-title>
      <v-list-tile-sub-title v-if="!before">{{action}} </v-list-tile-sub-title>
    </v-list-tile-content>
    <v-list-tile-action>
      <v-list-tile-action-text>{{ item.instant_formated }}</v-list-tile-action-text>
    </v-list-tile-action>
  </v-list-tile> 
</template>
<script>
  export default {
    name: 'WiTimeLineItemName',
    props: {
      item: {
        required: true,
        default: {}
      },
      action: {
        default: 'Executou: '
      },
      before: {
        default: false
      }
    }
  }
</script>
