<template>
  <div>
    <view-detail :index="props.index || null" :apiUrl="props.api.url" :apiParams="props.api.params" notShowToolbar="true" notGetID="true">
      <template slot-scope="items">
        <div style="padding: 0 10px 0 10px;">
          <v-timeline v-if="items.content && items.content.data && items.content.data.length > 0" :dense="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
            <v-timeline-item large v-for="(item, itemKey) in items.content.data" :key="itemKey" style="background: white !important;">
              <template v-slot:icon>
                <v-avatar color="white" style="background-color: white !important;">
                  <img :src="'static/icons/monitor/' + item.type_info.icon + '.png'">
                </v-avatar>
              </template>
              <template v-slot:opposite>
                <span class="headline font-weight-bold blue--text">
                  {{item.type_info.title}}
                </span>
              </template>
              <wi-time-line-item-diagnostic
                v-if="showItemDiagnostic(item)"
                :item="item"
                :action="item.type_info.action"
              ></wi-time-line-item-diagnostic>
              <wi-time-line-item-note
                 v-else
                :item="item"
                :action="item.type_info.action"
              ></wi-time-line-item-note>
            </v-timeline-item>
          </v-timeline>
          <div v-else style="text-align: center; padding: 20px;">
            <v-alert :value="true" type="warning" >
              <span>Nenhuma nota até o momento.</span>
            </v-alert>
          </div>
        </div>
      </template>
    </view-detail>
  </div>
</template>
<script>
  import WiTimeLineItemNote from '@/default/component/WiTimeLine/Items/WiTimeLineItemNote'
  import WiTimeLineItemDiagnostic from '@/default/component/WiTimeLine/Items/WiTimeLineItemDiagnostic'
  export default {
    data: () => ({
      types: [
        {},
        {type: 1, title: 'Criação', action: 'Foi criado por:', image: 'flying_stork_with_bundle_96'},
        {type: 2, title: 'Nota', action: 'Adicionou a nota:', image: 'note_96'},
        {type: 3, title: 'Status', action: 'Alterou o Status:', image: 'data_pending_96'},
        {type: 4, title: 'Anexar Arquivo', action: 'Adicionou 1 anexo:', image: 'anexo_96'},
        {type: 5, title: 'Data de Emissão', action: 'Alterou a data de Emissão:', image: 'date_start_96'},
        {type: 6, title: 'Data de Entrega', action: 'Alterou a data de Entrega:', image: 'schedule_96'},
        {type: 7, title: 'Data de Validade', action: 'Alterou a data de Validade:', image: 'event_time_96'},
        {type: 8, title: 'Código Externo', action: 'Alterou o código externo:', image: 'dog_tag_96'},
        {type: 9, title: 'Responsável', action: 'Alterou o Responsável:', image: 'worker_male_96'},
        {type: 10, title: 'Cliente', action: 'Alterou o Cliente:', image: 'company_96'},
        {type: 11, title: 'Categoria', action: 'Alterou a Categoria', image: 'transfer_96'},
        {type: 12, title: 'Conversão', action: 'Converteu', image: 'transfer_96'}
      ]
    }),
    methods: {
      showItemDiagnostic (item) {
        return item.type == 20 
          || item.type == 21
          || item.type == 22
          || item.type == 23
          || item.type == 24
          || item.type == 25
          || item.type == 27
      }
      
    },
    props: {
      props: {
        required: true,
        default: {}
      }
    },
    components: {
      WiTimeLineItemNote,
      WiTimeLineItemDiagnostic,
    }
  }
</script>
<style>
  .v-timeline-item__inner-dot .primary {
    background-color: white !important;
  }
</style>
