<template>
  <v-card  class="elevation-2" :style="{background: $wiDark ? '' : '#F2F2F2'}">
    <v-list subheader one-line dense :style="{background: $wiDark ? '' : '#F2F2F2'}">
      <wi-time-line-item-person :item="item" :action="action"></wi-time-line-item-person>
    </v-list>
    <v-divider></v-divider>
    <div style="width: 100%; text-align: center;" :style="{background: item.status_info.color}">
      <v-progress-circular
        v-if="item.status == 1"
        :indeterminate="true"
        :size="23"
        :width="2"
        color="white"
      ></v-progress-circular>
      <v-icon v-else color="white">{{item.status_info.icon}}</v-icon>
      <span style="font-size: 18px; padding-left: 5px; color: white;">
        {{item.status_info.title}}
      </span>
    </div>
    <v-divider></v-divider>
    <v-card-text v-if="showMessagesArea">
      <ul>
        <li v-for="(message, messageKey) in item.complement.diagnostic.messages" :key="messageKey">
          <strong>{{message.criticity}} </strong>
          <span> {{message.description}} </span>
          <span> ({{message.errorCode}})</span>
        </li>
      </ul>
    </v-card-text>
    <v-divider v-if="showMessagesArea"></v-divider>
    <v-card-text v-if="itemResult">
      <v-layout row wrap>
        <v-flex v-if="itemResult.orders" xs6 sm6 md3 lg3 xl3 d-flex style="padding-right: 5px;">
          <v-card>
            <v-card-text style="text-align: center;">
              <h1>{{itemResult.orders.length}}</h1>
              <strong>Orden(s)</strong>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex v-if="itemResult.massiveIncidents" xs6 sm6 md3 lg3 xl3 d-flex style="padding: 0 5px;">
          <v-card>
            <v-card-text style="text-align: center;">
              <h1>{{itemResult.massiveIncidents.length}}</h1>
              <strong>Massivo(s)</strong>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex v-if="itemResult.wlanConfigurations" xs6 sm6 md3 lg3 xl3 d-flex style="padding: 0 5px;">
          <v-card>
            <v-card-text style="text-align: center;">
              <h1>{{itemResult.wlanConfigurations.length}}</h1>
              <strong>Wlan(s)</strong>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex v-if="itemResult.services" xs6 sm6 md3 lg3 xl3 d-flex style="padding-left: 5px;">
          <v-card>
            <v-card-text style="text-align: center;">
              <h1>{{itemResult.services.length}}</h1>
              <strong>Serviços(s)</strong>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider v-if="itemResult"></v-divider>
    <v-card-text v-if="item.actions && item.actions.length > 0">
      <v-menu offset-y>
        <template v-slot:activator="{ on }"> 
          <v-btn v-on="on" :disabled="item.status != 2" block outline color="primary">
            <v-icon>more_vert</v-icon>
            <span>Opções</span>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(action, actionKey) in item.actions">
            <v-list-tile :key="`${actionKey}-tile`" @click="menuAction(action)">
              <v-list-tile-action>
                <v-icon large v-text="action.icon"></v-icon>
              </v-list-tile-action>
              <v-list-tile-title v-text="action.title"></v-list-tile-title>
            </v-list-tile>
            <v-divider :key="`${actionKey}-divider`"></v-divider>
          </template>
        </v-list>
      </v-menu>
    </v-card-text>
  </v-card>
</template>
<script>
  import WiTimeLineItemPerson from '@/default/component/WiTimeLine/Items/WiTimeLineItemPerson'
  export default {
    name: 'WiTimeLineItemDiagnostic',
    computed: {
      showMessagesArea () {
        return this.item?.complement?.diagnostic?.messages?.length > 0
      },
      itemResult () {
        return this.item?.complement?.result
      }
    },
    methods: {
      menuAction: function (action) {
        this.$WiApiPost({
          uri: 'networks/test-management-vtal/event/action',
          data: action.content,
          config: {
            msgLoad: true,
            msgSucess: true
          },
          callback: (response) => {
            console.log(response)
          }
        })
      }
    },
    props: {
      item: {
        required: true,
        default: {}
      },
      action: {
        default: 'Executou:'
      }
    },
    components: {
      WiTimeLineItemPerson
    }
  }
</script>
